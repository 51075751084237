<template>
    <div class="tabs">
        <div v-for="item in tablist" :key="item.id" :class="{ 'current': item.id === current }"
            @click="changeCurrent(item)" class="tab-item">{{ item.label }}</div>
    </div>
</template>

<script>
import { asyncRoutes } from '@/router/index'
import router from '@/router/index'

export default {
    name: 'VueElementTupaiEconnav',
    props: {
        cate: {
            type: String,
            default: 'econ'
        }
    },
    data() {
        return {
            current: "gonggdp",
            tablist: []
        };
    },

    computed: {
        menu() {
           return JSON.parse(this.$store.state.user.menu)
        }
    },

    mounted() {
        this.getRouteDataAsTabMenu()
        //根据当前路由获取设置当前tab页

        this.current = this.$route.name


    },
    methods: {
        //获取路由文件的数据
        getRouteDataAsTabMenu() {
            let econ = []
            asyncRoutes.map(item => {
                if (item != null) {
                    if (item.children) {
                        item.children.filter(subitem => {
                            if (subitem != null) {
                                if (subitem.name === this.cate) {
                                    if (subitem.children != null) {
                                        econ = subitem.children
                                        return false;
                                    }
                                }
                            }
                        })
                    }


                }
            })
            this.tablist = this.handleRouteToTabData(econ)
            // console.log('list', this.tablist)
        },
        //获取当前用户的权限菜单数组
        getUserMenuByUserRoute(routes) {
            let menus = []
            routes.map(item => {
                if (item.name ) {
                    menus.push(item.name)
                }
            })
            return menus
        },

        //处理路由文件的数据
        handleRouteToTabData(econ) {
            
            const menus = this.getUserMenuByUserRoute(this.menu)
            let tablist = []
            econ.map(item => {
                if (item.meta && item.meta.menu) {
                    if (menus.indexOf(item.meta.menu) > -1) {
                        // alert(item.meta.menu)
                        const obj = {}
                        obj.id = item.name
                        obj.label = item.meta.name
                        tablist.push(obj)
                    }
                }

            })
            // console.log('===tablist', tablist)
            return tablist
        },

        changeCurrent(item) {
            if (this.current !== item.id) {
                const currentId = item.id;
                this.current = currentId
                this.$router.push({ name: item.id })
            }

        }
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    text-align: left;
    width: 100%;

    .tab-item {
        height: 30px;
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
        border-bottom: solid 2px #F2F2F2;
        cursor: pointer;
    }

}


.tab-item.current {
    border-bottom: solid 2px#28A7E1;
    color: #28A7E1;
    font-weight: 500;
    padding: 0;
    margin:0 10px;
}
</style>